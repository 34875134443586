import "../css/InfiniteSlider.sass";
import "swiper/swiper.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import React from "react";

// import { motion } from "framer-motion";

SwiperCore.use([Autoplay]);

function InfiniteSlider(props) {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1.5}
      centeredSlides
      slidesPerGroup={1}
      autoplay={{ delay: 0, disableOnInteraction: false }}
      speed={5000}
      loop={true}
      //   onSlideChange={() => console.log("slide change")}
      //   onSwiper={(swiper) => console.log(swiper)}
    >
      {props.imgTab.map((img, index) => {
        console.log(img.fields.file.contentType);
        if (
          img.fields.file.contentType.includes("jpeg") ||
          img.fields.file.contentType.includes("png")
        ) {
          return (
            <SwiperSlide key={index}>
              <img
                // animate={{ boxShadow: "5px 5px #" + props.accentColor }}
                // whileTap={{ boxShadow: "0px 0px #" + props.accentColor }}
                className="videoPageStill"
                src={img.fields.file.url}
                alt=""
              />
            </SwiperSlide>
          );
        } else {
          return null;
        }
      })}
    </Swiper>
  );
}

export default InfiniteSlider;
