import "../css/ButtonFilter.sass";

import React from "react";
import { motion } from "framer-motion";

function ButtonFilter({ value, show, setShow, accentColor }) {
  let accentColorCss = accentColor;
  // console.log("vhmobileheight : ", vhmobileheight);
  document.documentElement.style.setProperty(
    "--accentColor",
    `#${accentColorCss}`
  );
  return (
    <div
      // animate={{
      //   boxShadow: show ? "5px 5px #" + accentColor : "0px 0px #" + accentColor,
      // }}
      className="buttonContainer"
      onClick={() => setShow(!show)}
    >
      <motion.div
        animate={{ width: show ? "15%" : "0px", scale: show ? 1 : 0 }}
        className="buttonIcon"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.1716L16.2426 7.75739L10.5858 13.4142Z"
            fill="currentColor"
          />
        </svg>
      </motion.div>
      <motion.div
        animate={{ width: show ? "75%" : "100%" }}
        className="buttonText"
      >
        {value}
      </motion.div>
    </div>
  );
}

export default ButtonFilter;
