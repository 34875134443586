import "../css/NotFound.sass";

import { Link } from "react-router-dom";
import Logo from "../components/Logo.jsx";
import React from "react";

function NotFound() {
  //if (process.env.NODE_ENV === "production") {
  //window.FB.CustomerChat.hide();
  //}
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      {/* <div className="notFoundBg">
        <Logo />
      </div> */}
      <div className="notFoundContainer">
        <div className="notFoundContent">
          <Logo />
        </div>
        <div className="notFoundContent">
          <div className="notFoundText">Page not found.</div>
          <br />
          <span className="notFoundText subText" role="img" aria-label="home">
            🏠
          </span>
        </div>
      </div>
    </Link>
  );
}

export default NotFound;
