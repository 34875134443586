import "../css/VideosList.sass";

import { AnimatePresence, motion } from "framer-motion";
import { isMobileSafari, osVersion } from "react-device-detect";

import { Link } from "react-router-dom";
import React from "react";
import VideoBlock from "./VideosBlock.jsx";
import nothing from "../images/nothing.gif";

// import { motion } from "framer-motion";

// var safari13 = "UNKNOWN";
var videoVariants;

if (isMobileSafari && osVersion < 13) {
  console.log("mobile safari < 13.4");
  videoVariants = {
    initial: {
      opacity: 0,
      height: 0,
    },
    animate: (i) => ({
      opacity: 1,
      // height: "15vw",
      height: "15vw",
      transition: { delay: i * 0.1 },
    }),
    exit: (i) => ({
      opacity: 0,
      height: 0,
      transition: { delay: i * 0.1 },
    }),
  };
} else {
  videoVariants = {
    initial: {
      opacity: 0,
      height: 0,
    },
    animate: (i) => ({
      opacity: 1,
      // height: "15vw",
      height: "clamp(80px, 15vw, 150px)",
      transition: { delay: i * 0.1 },
    }),
    exit: (i) => ({
      opacity: 0,
      height: 0,
      transition: { delay: i * 0.1 },
    }),
  };
}

function VideosList({
  videos,
  showCommercial,
  showFiction,
  showMusic,
  setFilter,
}) {
  // console.log("showCommercial : ", showCommercial);
  // console.log("showFiction : ", showFiction);
  // console.log("showMusic : ", showMusic);
  return (
    <div className="home_videos_inner">
      <AnimatePresence>
        {!showCommercial && !showFiction && !showMusic && (
          <div className="resetFiltersContainer">
            <motion.img
              key="nothing"
              initial={{
                height: "0px",
                opacity: 0,
              }}
              animate={{
                height: "120px",
                opacity: 1,
              }}
              exit={{
                height: "0px",
                opacity: 0,
              }}
              className="resetFilters"
              onClick={() => setFilter()}
              src={nothing}
            ></motion.img>
          </div>
        )}

        {videos.map((video, index) => {
          const videoBlocks = () => {
            if (!video.fields.cacher) {
              return (
                <Link
                  className="videoLineinner"
                  to={"/video/" + video.fields.url}
                >
                  <VideoBlock
                    texte={video.fields.client}
                    date={
                      video.fields.categorie === "Fiction"
                        ? video.fields.date
                        : ""
                    }
                    img={video.fields.gifsStills[0].fields.file.url}
                  />
                  <VideoBlock
                    texte={"« " + video.fields.titre + " »"}
                    img={video.fields.gifsStills[1].fields.file.url}
                  />
                  <VideoBlock
                    texte={video.fields.categorie}
                    img={video.fields.gifsStills[2].fields.file.url}
                  />
                </Link>
              );
            } else {
              return (
                <div className="videoLineinner videoLineInactive">
                  <VideoBlock
                    texte={video.fields.client}
                    date={
                      video.fields.categorie === "Fiction"
                        ? video.fields.date
                        : ""
                    }
                    img={video.fields.gifsStills[0].fields.file.url}
                  />
                  <VideoBlock
                    texte={"« " + video.fields.titre + " »"}
                    img={video.fields.gifsStills[1].fields.file.url}
                  />
                  <VideoBlock
                    texte={video.fields.categorie}
                    img={video.fields.gifsStills[2].fields.file.url}
                  />
                </div>
              );
            }
          };

          if (
            (video.fields.categorie === "Commercial" && showCommercial) ||
            (video.fields.categorie === "Fiction" && showFiction) ||
            (video.fields.categorie === "Music Video" && showMusic)
          ) {
            return (
              <motion.div
                className="videoLineContainer"
                initial="initial"
                animate="animate"
                exit="exit"
                // whileHover={{
                //   marginTop: "15px",
                //   marginBottom: "15px",
                // }}
                variants={videoVariants}
                custom={index}
                key={index}
              >
                {videoBlocks()}
              </motion.div>
            );
          } else {
            return null;
          }
        })}
      </AnimatePresence>
    </div>
  );
}

export default VideosList;
