import "../css/Footer.sass";

import { FaEnvelope, FaInstagram, FaVimeoSquare } from "react-icons/fa";

import React from "react";
// import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

function Footer({ img, accentColor, variablesData }) {
  const history = useHistory();

  return (
    <footer
      // initial={{ boxShadow: "10px -10px 0px 0px #" + accentColor }}
      // whileHover={{ boxShadow: "0px 0px 0px 0px #" + accentColor }}
      // whileTap={{ boxShadow: "0px 0px 0px 0px #" + accentColor }}
      className="footer_inner"
    >
      <div className="block">
        <img className="mirror" src={img} alt="chewbacca" />
      </div>
      <div className="block middle">
        <h1 onClick={() => history.push("/")} className="title">
          Cloé Bailly
        </h1>
        <div className="iconsContainer">
          <a
            href={"mailto:" + variablesData.mail}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
          <a
            href={variablesData.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href={variablesData.vimeo}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaVimeoSquare />
          </a>
        </div>
        <div className="credits">{variablesData.copyrightFooter}</div>
      </div>
      <div className="block">
        <img src={img} alt="chewbacca" />
      </div>
    </footer>
  );
}

export default Footer;
