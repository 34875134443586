import "../css/Home.sass";

import React, { useState } from "react";

import ButtonFilter from "../components/ButtonFilter";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import VideosList from "../components/VideosList";

function Home({ homeData, variablesData }) {
  const [showCommercial, setShowCommercial] = useState(true);
  const [showFiction, setShowFiction] = useState(true);
  const [showMusic, setShowMusic] = useState(true);

  // console.log("homeData : ", homeData);
  // console.log("variablesData : ", variablesData);

  return (
    <div className="home_container">
      <Nav accentColor={variablesData.couleur} />
      <div className="home_logo">
        <Logo
          texteBandeau={variablesData.texteBandeau}
          bg={homeData.bgVideo.fields.file.url}
          scrollIcon
          accentColor={variablesData.couleur}
        />
      </div>
      <div className="home_filters_container">
        <div className="home_filters_inner">
          <ButtonFilter
            value={variablesData.filtreCommercials}
            show={showCommercial}
            setShow={setShowCommercial}
            accentColor={variablesData.couleur}
          />
          <ButtonFilter
            value={variablesData.filtreFiction}
            show={showFiction}
            setShow={setShowFiction}
            accentColor={variablesData.couleur}
          />
          <ButtonFilter
            value={variablesData.filtreMusicVideos}
            show={showMusic}
            setShow={setShowMusic}
            accentColor={variablesData.couleur}
          />
        </div>
      </div>
      <div className="home_videos_container">
        <VideosList
          videos={homeData.listeVideos}
          showCommercial={showCommercial}
          showFiction={showFiction}
          showMusic={showMusic}
          setFilter={() => {
            setShowCommercial(true);
            setShowFiction(true);
            setShowMusic(true);
          }}
        />
      </div>
      <div className="footer_container">
        <Footer
          variablesData={variablesData}
          img={variablesData.imageFooter.fields.file.url}
          accentColor={variablesData.couleur}
        />
      </div>
    </div>
  );
}

export default Home;
