import "../css/Contact.sass";

import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import Nav from "../components/Nav";
import React from "react";
import ReactMarkdown from "react-markdown/with-html";

function Contact({ homeData, variablesData }) {
  return (
    <div className="contact_container">
      <Nav accentColor={variablesData.couleur} />
      <Link to="/" className="contact_logo">
        <Logo
          texteBandeau={variablesData.texteBandeau}
          accentColor={variablesData.couleur}
        />
      </Link>
      <div className="contactTextContainer">
        <div className="contactTextInner">
          <ReactMarkdown
            children={variablesData.pageContact}
            escapeHtml={false}
          />
        </div>
      </div>
      <div className="footer_container">
        <Footer
          variablesData={variablesData}
          img={variablesData.imageFooter.fields.file.url}
          accentColor={variablesData.couleur}
        />
      </div>
    </div>
  );
}

export default Contact;
