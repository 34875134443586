import "../css/Nav.sass";

import React, { useContext } from "react";

import LocaleContext from "../LocaleContext.jsx";
import ScrollingUpContext from "../ScrollingUpContext.jsx";
import icon_flag_fr from "../images/icon-flag-fr.png";
import icon_flag_uk from "../images/icon-flag-uk.png";
import icon_hand from "../images/icon-hand.png";
import icon_house from "../images/icon-house.png";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

function Nav({ accentColor }) {
  const [locale, setLocale] = useContext(LocaleContext);
  const scrollingUp = useContext(ScrollingUpContext);
  const history = useHistory();

  const navInner = {
    hidden: {},
    show: {},
  };

  const navButton = {
    hidden: { marginTop: "-80px" },
    // boxShadow: "5px 5px #" + accentColor
    show: { marginTop: "15px" },
    // whileHover: { boxShadow: "0px 0px #" + accentColor },
    // whileTap: { boxShadow: "0px 0px #" + accentColor },
  };

  console.log("scrollingUp : ", scrollingUp);

  return (
    <motion.div
      animate={{ top: scrollingUp ? "0px" : "-80px" }}
      className="navContainer"
    >
      <motion.div
        variants={navInner}
        initial="hidden"
        animate="show"
        transition={{
          delayChildren: 0.5,
          staggerChildren: 0.4,
        }}
        className="navInner"
      >
        <motion.div
          variants={navButton}
          whileHover="whileHover"
          whileTap="whileTap"
          className="navButtonContainer"
          onClick={() => history.push("/")}
        >
          <img src={icon_house} alt="nav icon" />
        </motion.div>

        <motion.div
          variants={navButton}
          whileHover="whileHover"
          whileTap="whileTap"
          className="navButtonContainer"
          onClick={() => history.push("/contact")}
        >
          <img src={icon_hand} alt="nav icon" />
        </motion.div>

        <motion.div
          variants={navButton}
          whileHover="whileHover"
          whileTap="whileTap"
          className="navButtonContainer"
          onClick={() => setLocale(locale === "fr" ? "en-US" : "fr")}
        >
          <img
            src={locale === "fr" ? icon_flag_fr : icon_flag_uk}
            alt="nav icon"
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Nav;
