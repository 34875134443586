import "./App.css";

// import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import Contact from "./pages/Contact";
import ContentfulContext from "./ContentfulContext.jsx";
import Home from "./pages/Home";
import LocaleContext from "./LocaleContext.jsx";
import NotFound from "./pages/NotFound";
import ScrollingUpContext from "./ScrollingUpContext.jsx";
import VideoPage from "./pages/VideoPage";

function App() {
  const [variablesData, setVariablesData] = useState();
  const [homeData, setHomeData] = useState();
  const [locale, setLocale] = useState("fr");
  const [scrollingUp, setScrollingUp] = useState(true);
  const client = useContext(ContentfulContext);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollingUp(scrollY < lastScrollY || lastScrollY === 0 ? true : false);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  let vhmobileheight = window.innerHeight;
  document.documentElement.style.setProperty(
    "--vhmobileheight",
    `${vhmobileheight}px`
  );
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vhmobileheight = window.innerHeight;
    // console.log("vhmobileheight : ", vhmobileheight);
    document.documentElement.style.setProperty(
      "--vhmobileheight",
      `${vhmobileheight}px`
    );
  });

  useEffect(() => {
    //LOAD HOME DATA
    client
      .getEntry("5m9rpL42spYgsA10w0W7nf", { locale: locale })
      .then((entry) => {
        setHomeData(entry.fields);
      })
      .catch(console.error);

    //LOAD VARIABLES DATA
    client
      .getEntry("5zRu60g8lbM0q6WIz5nlIK", { locale: locale })
      .then((entry) => {
        setVariablesData(entry.fields);
        let accentColor = entry.fields.couleur;
        // console.log("vhmobileheight : ", vhmobileheight);
        document.documentElement.style.setProperty(
          "--accentColor",
          `#${accentColor}`
        );
      })
      .catch(console.error);
  }, [client, locale]);

  //WAIT FOR DATA LOAD
  if (!variablesData || !homeData) {
    return null;
  }

  const Routes = [
    {
      path: "/",
      title: "Cloé Bailly",
      component: <Home homeData={homeData} variablesData={variablesData} />,
      exact: true,
    },
    {
      path: "/contact",
      title: "Cloé Bailly",
      component: <Contact homeData={homeData} variablesData={variablesData} />,
      exact: true,
    },
  ];

  //_________________________________________ RETURN
  return (
    <div className="App">
      <LocaleContext.Provider value={[locale, setLocale]}>
        <ScrollingUpContext.Provider value={scrollingUp}>
          <Switch>
            {Routes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                render={() => {
                  return route.component;
                }}
                // component={route.component}
              />
            ))}
            <Route
              exact
              path="/video/:video_slug"
              render={({ props, match }) => {
                return (
                  <VideoPage
                    homeData={homeData}
                    variablesData={variablesData}
                    props={props}
                    match={match}
                  />
                );
              }}
            />
            <Route
              render={() => {
                return <NotFound />;
              }}
            />
          </Switch>
        </ScrollingUpContext.Provider>
      </LocaleContext.Provider>
    </div>
  );
}

export default App;
