import "../css/VideoPage.sass";

import React, { useState } from "react";

import Footer from "../components/Footer";
import InfiniteSlider from "../components/InfiniteSlider";
import Nav from "../components/Nav";
import NotFound from "./NotFound.jsx";
import ReactMarkdown from "react-markdown/with-html";
import ReactPlayer from "react-player/vimeo";

function VideoPage(props) {
  const [playing, setPlaying] = useState(false);
  var videoSlug = props.match.params.video_slug;

  const videoData = props.homeData.listeVideos.filter(
    (video) => video.fields.url === videoSlug
  );

  // console.log("videoData : ", videoData[0].fields);
  // console.log("playing : ", playing);

  if (videoData[0].fields.cacher) {
    return <NotFound />;
  }

  return (
    <div className="videoPageContainer">
      <Nav accentColor={props.variablesData.couleur} />
      <div
        className="videoPageHeaderContainer"
        onClick={() => setPlaying(!playing)}
      >
        <ReactPlayer
          url={videoData[0].fields.lien}
          controls
          playing={playing}
          width="100vw"
          height="100%"
          style={{ objectFit: "cover" }}
        />
      </div>
      <h1 className="videoPageTitleContainer">
        « {videoData[0].fields.titre.toUpperCase()} »
      </h1>
      <InfiniteSlider
        imgTab={videoData[0].fields.gifsStills}
        accentColor={props.variablesData.couleur}
      />
      <div className="videoPageCreditsContainer">
        <div className="videoPageCreditsInner">
          <ReactMarkdown
            source={videoData[0].fields.credits}
            escapeHtml={false}
          />
        </div>
      </div>
      <div className="footer_container">
        <Footer
          variablesData={props.variablesData}
          img={props.variablesData.imageFooter.fields.file.url}
          accentColor={props.variablesData.couleur}
        />
      </div>
    </div>
  );
}

export default VideoPage;
