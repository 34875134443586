import "../css/Logo.sass";

import React from "react";
import { motion } from "framer-motion";

// import { BrowserView, MobileView } from "react-device-detect";

// import scrollIconGif from "../images/scroll.gif";

const prenomTab = ["C", "l", "o", "é"];
const nomTab = ["B", "a", "i", "l", "l", "y"];

function Logo(props) {
  // var variantsLogo = {
  //   noshadow: (i) => ({
  //     textShadow: "0vh 0vh #" + props.accentColor,
  //   }),
  //   shadow: (i) => ({
  //     textShadow: "1vh 1vh #" + props.accentColor,
  //     transition: {
  //       delay: (i + 5) * 0.1,
  //     },
  //   }),
  // };
  // if (isMobile) {
  //   variantsLogo = {
  //     noshadow: (i) => ({
  //       textShadow: "0px 0px #" + props.accentColor,
  //     }),
  //     shadow: (i) => ({
  //       textShadow: "7px 7px #" + props.accentColor,
  //       transition: {
  //         delay: (i + 5) * 0.1,
  //       },
  //     }),
  //   };
  // }

  // let titles = "";
  // for (let i = 0; i < 15; i++) {
  //   titles += props.texteBandeau;
  // }
  // const bannerTitles = titles;

  const revealedBanner = () => {
    let lettersTab = props.texteBandeau.split("");
    return lettersTab.map((letter, index) => <span key={index}>{letter}</span>);
  };

  // const prenomShadow = prenomTab.map((letter, i) => {
  //   if (letter === "H") {
  //     return (
  //       <motion.span
  //         key={i}
  //         initial={{
  //           opacity: 1,
  //           fontSize: "20vw",
  //           color: "#" + props.accentColor,
  //         }}
  //         animate={{
  //           opacity: 0,
  //           fontSize: "0px",
  //           color: "#" + props.accentColor,
  //         }}
  //         transition={{ delay: 1.5 }}
  //       >
  //         {letter}
  //       </motion.span>
  //     );
  //   } else {
  //     return (
  //       <motion.span
  //         key={i}
  //         custom={i}
  //         initial="noshadow"
  //         animate="shadow"
  //         variants={variantsLogo}
  //         whileHover="noshadow"
  //         whileTap="noshadow"
  //       >
  //         {letter}
  //       </motion.span>
  //     );
  //   }
  // });

  const prenom = prenomTab.map((letter, i) => {
    if (letter === "H") {
      return (
        <motion.span
          key={i}
          initial={{
            opacity: 1,
            fontSize: "20vw",
            color: "#" + props.accentColor,
          }}
          animate={{
            opacity: 0,
            fontSize: "0px",
            color: "#" + props.accentColor,
          }}
          transition={{ delay: 1.5 }}
          // className="outlinedletter"
        >
          {letter}
        </motion.span>
      );
    } else {
      return (
        <span
          key={i}
          className={
            props.bg ? "outlinedletter" : "outlinedletter borderLetter"
          }
        >
          {letter}
        </span>
      );
    }
  });

  // const nomShadow = nomTab.map((letter, i) => (
  //   <motion.span
  //     key={i}
  //     className="shadowletter"
  //     custom={i + 4}
  //     initial="noshadow"
  //     animate="shadow"
  //     variants={variantsLogo}
  //     whileHover="noshadow"
  //     whileTap="noshadow"
  //   >
  //     {letter}
  //   </motion.span>
  // ));

  const nom = nomTab.map((letter, i) => (
    <span
      key={i}
      className={props.bg ? "outlinedletter" : "outlinedletter borderLetter"}
    >
      {letter}
    </span>
  ));

  return (
    <>
      {props.bg && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1.5,
            delay: 1,
          }}
          className="videoBgContainer"
        >
          {/* <div className="videoBg"> */}
          <video muted loop autoPlay playsInline className="videoBg">
            <source src={props.bg} type="video/mp4" />
          </video>
          {/* </div> */}
        </motion.div>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="logo"
      >
        {/* <div className="logoLineContainer">
          <h1 className="logoLine">{prenomShadow}</h1>
          <h1 className="logoLine">{nomShadow}</h1>
        </div> */}
        <div style={{ pointerEvents: "none" }} className="logoLineContainer">
          <h1 className="logoLine">{prenom}</h1>
          <h1 className="logoLine">{nom}</h1>
        </div>
      </motion.div>
      {/* ----------- BANNIÈRE */}
      {/* <div className="bannerContainer">
        <motion.div
          initial={{ x: "100%", y: "-50vh", rotate: -40 }}
          animate={{ x: 0, y: 0, rotate: -10 }}
          transition={{
            duration: 2,
            delay: 0.6,
          }}
          className="banner"
        >
          <h5>{bannerTitles}</h5>
        </motion.div>
      </div> */}
      {/* ----------- BANNIÈRE */}

      {props.bg && (
        <div className="softBannerContainer">
          <div className="banner">{revealedBanner()}</div>
        </div>
      )}
      {/* ----------- SCROLL ICON */}
      {/* {props.scrollIcon && (
        <div className="scrollIconContainer">
          <BrowserView>
            <img
              onClick={() =>
                window.scrollTo(0, (100 * window.innerHeight) / 100)
              }
              src={scrollIconGif}
              alt="scroll"
            />
          </BrowserView>
          <MobileView>
            <img src={scrollIconGif} alt="scroll" />
          </MobileView>
        </div>
      )} */}
      {/* ----------- SCROLL ICON */}
    </>
  );
}

export default Logo;
