import React from "react";
// import ReactPlayer from "react-player";

function VideosBlock({ img, texte, date }) {
  const dateFormated = new Date(date).toLocaleDateString("fr-FR");

  // console.log("img : ", img);

  return (
    <div className="videoBlockContainer">
      {/* ------------IMAGE-------------- */}
      {/* {img.includes("webm") && (
        <ReactPlayer
          className="videoBlockContentImage"
          url={img}
          playing={true}
          loop
          width="100%"
          // height="100%"
          style={{ objectFit: "cover", minHeight: "100%", minWidth: "100%" }}
        />
      )}
      {(img.includes("jpg") || img.includes("gif")) && (
        <img className="videoBlockContentImage" src={img} alt="screen1" />
      )} */}
      <img className="videoBlockContentImage" src={img} alt="screen1" />

      {/* ------------IMAGE-------------- */}
      {/* ------------TEXTE-------------- */}
      <div id="screen1" className="videoBlockContentText">
        {texte}
        {date && <div className="text2">{dateFormated}</div>}
      </div>
      {/* ------------TEXTE-------------- */}
    </div>
  );
}

export default VideosBlock;
